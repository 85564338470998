<template>
  <div>
    <v-stepper v-model="stepPosition" vertical>
      <v-stepper-step :complete="stepPosition > 1" step="1"
        >Dates</v-stepper-step
      >
      <v-stepper-content step="1" class="pt-2">
        <v-menu
          ref="menu"
          v-model="datePicker.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Choose Dates"
              v-model="dateRangeText"
              prepend-inner-icon="mdi-calendar"
              outlined
              class="mt-1"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-date-picker
            v-model="fields.dates"
            first-day-of-week="1"
            range
            :allowed-dates="allowedDates"
            :min="datePicker.min"
            @change="datesSelected"
            @update:picker-date="monthChanged"
          />
        </v-menu>
        <div>
          <v-btn color="primary" @click="loadPodStep()" :loading="loaders.pod"
            >Continue</v-btn
          >
        </div>
      </v-stepper-content>
      <v-stepper-step :complete="stepPosition > 2" step="2"
        >Pods</v-stepper-step
      >
      <v-stepper-content step="2">
        <v-checkbox
          v-for="pod in availablePods"
          :key="pod.id"
          v-model="fields.pods"
          :label="pod.name"
          :value="pod.id"
          class="mt-0"
        />
        <div>
          <v-btn color="primary" @click="stepPosition = 3">Continue</v-btn>
          <v-btn text @click="stepPosition = 1">Back</v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-step :complete="stepPosition > 3" step="3"
        >Extras</v-stepper-step
      >
      <v-stepper-content step="3">
        <div class="pt-1">
          <v-select
            label="Extras"
            v-model="fields.extras"
            :items="extras"
            item-text="name"
            item-value="id"
            outlined
            chips
            multiple
          ></v-select>
          <div
            v-for="(extra, index) in fields.extraQuantities"
            :key="`${extra.id}-extra-quantity`"
          >
            <v-text-field
              :label="getExtraQuantityLabel(extra.id)"
              v-model="fields.extraQuantities[index].quantity"
              outlined
              type="number"
            ></v-text-field>
          </div>
        </div>
        <div>
          <v-btn color="primary" @click="loadSwimStep()" :loading="loaders.swim"
            >Continue</v-btn
          >
          <v-btn text @click="stepPosition = 2">Back</v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-step :complete="stepPosition > 4" step="4"
        >Swimming</v-stepper-step
      >
      <v-stepper-content step="4">
        <div v-if="swimSlots.anyAvailable">
          <div
            v-for="swimSlot in swimSlots.slots"
            :key="`${swimSlot.date}-swim-slot`"
            class="mb-4"
          >
            <span class="subheading">{{ swimSlot.date }}</span>
            <v-chip-group
              v-model="fields.swimSlot"
              active-class="blue--text"
              column
            >
              <v-chip
                v-for="(slot, index) in swimSlot.time_slots"
                :key="`${index}-time-slot`"
                :value="slot.datetime"
                >{{ slot.start_time }}</v-chip
              >
            </v-chip-group>
          </div>
        </div>
        <div v-else class="mb-6">
          There are no Swim Slots available for the duration of this booking
        </div>
        <div>
          <v-btn color="primary" @click="stepPosition = 5">Continue</v-btn>
          <v-btn text @click="stepPosition = 3">Back</v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-step :complete="stepPosition > 5" step="5"
        >Customer Details</v-stepper-step
      >
      <v-stepper-content step="5">
        <v-select
          label="Customer *"
          v-model="fields.customer_id"
          :items="customers"
          item-text="full_name"
          item-value="id"
          outlined
          background-color="white"
        >
          <template v-slot:prepend-item>
            <div class="pa-3">
              <v-text-field
                label="Search Customer"
                v-model="customerSearchTerm"
                outlined
                autofocus
                dense
                hide-details
              ></v-text-field>
            </div>
            <v-divider></v-divider>
          </template>
        </v-select>

        <div>
          <v-btn
            color="primary"
            @click="loadSummaryStep()"
            :loading="loaders.summary"
            >Continue</v-btn
          >
          <v-btn text @click="stepPosition = 4">Back</v-btn>
        </div>
      </v-stepper-content>
      <v-stepper-step :complete="stepPosition > 6" step="6"
        >Summary</v-stepper-step
      >
      <v-stepper-content step="6">
        <v-simple-table class="mb-4">
          <tbody>
            <tr>
              <th>Sub Total</th>
              <td class="text-right">£{{ fields.subTotal }}</td>
            </tr>
            <tr>
              <th>VAT Total</th>
              <td class="text-right">£{{ fields.vatTotal }}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td class="text-right">
                <v-text-field
                  v-model="fields.total"
                  outlined
                  prefix="£"
                  min="0"
                  type="number"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div>
          <v-btn
            color="primary"
            @click="createBooking()"
            :loading="loaders.complete"
            >Save</v-btn
          >
          <v-btn text @click="stepPosition = 5">Back</v-btn>
        </div>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepPosition: 1,
      customerSearchTerm: "",
      datePicker: {
        menu: false,
        min: new Date().toISOString().slice(0, 10),
        loading: true,
      },
      fields: {
        dates: [],
        pods: [],
        extras: [],
        extraQuantities: [],
        customer_id: null,
        swimSlot: null,
        subTotal: "0.00",
        vatTotal: "0.00",
        total: "0.00",
      },
      availablePods: [],
      swimSlots: {
        anyAvailable: false,
        slots: [],
      },
      overlay: false,
      loaders: {
        pod: false,
        swim: false,
        summary: false,
        complete: false,
      },
      totals: {
        subTotal: "0.00",
        vatTotal: "0.00",
        total: "0.00",
      },
    };
  },

  mounted() {
    Promise.all([
      this.$store.dispatch("arnprior/bookingsStore/loadExtras", {
        appId: this.$route.params.id,
      }),
      this.$store.dispatch("arnprior/customersStore/loadCustomers", {
        appId: this.$route.params.id,
      }),
    ]);
  },

  computed: {
    disabledDays() {
      return this.$store.getters["arnprior/podsStore/disabledDays"];
    },
    dateRangeText() {
      return this.fields.dates.map((date) => this.formatDate(date)).join(" ~ ");
    },
    extras() {
      return this.$store.getters["arnprior/bookingsStore/extras"];
    },
    customers() {
      let customers = this.$store.getters["arnprior/customersStore/all"];

      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const customerSearchTerm = this.customerSearchTerm.toLowerCase();
          return name.includes(customerSearchTerm);
        });
      }

      return customers;
    },
    chosenExtras() {
      return this.fields.extras;
    },
  },

  watch: {
    "fields.total": function (newVal) {
      this.fields.subTotal = (newVal / 1.2).toFixed(2);
      this.fields.vatTotal = (newVal - this.fields.subTotal).toFixed(2);
    },

    chosenExtras(extras) {
      let updatedExtras = [];

      extras.forEach((extra) => {
        const existingExtra = this.fields.extraQuantities.find((e) => {
          return e.id === extra;
        });

        if (existingExtra) {
          updatedExtras.push(existingExtra);
        } else {
          updatedExtras.push({
            id: extra,
            quantity: 1,
          });
        }
      });

      this.fields.extraQuantities = updatedExtras;
    },
  },

  methods: {
    allowedDates(date) {
      return !this.disabledDays.includes(date);
    },
    datesSelected(dates) {
      if (dates.length === 1) {
        return;
      }

      const minDate = dates[0] <= dates[1] ? dates[0] : dates[1];
      const maxDate = dates[0] <= dates[1] ? dates[1] : dates[0];

      this.disabledDays.forEach((disabledDay) => {
        if (minDate <= disabledDay && maxDate >= disabledDay) {
          this.fields.dates = [];
        }
      });
    },
    monthChanged(date) {
      this.overlay = true;

      this.$store
        .dispatch("arnprior/podsStore/getDisabledDays", {
          appId: this.$route.params.id,
          fields: {
            date: date.concat("-01"),
          },
        })
        .then(() => (this.overlay = false))
        .catch(() => (this.overlay = false));
    },
    loadPodStep() {
      this.loaders.pod = true;

      const dates = this.fields.dates.sort();

      this.$store
        .dispatch("arnprior/podsStore/getAvailablePods", {
          appId: this.$route.params.id,
          fields: {
            check_in: dates[0],
            check_out: dates[1],
          },
        })
        .then((resp) => {
          this.availablePods = resp.pods;

          this.loaders.pod = false;
          this.stepPosition = 2;
        })
        .catch(() => (this.loaders.pod = false));
    },
    loadSwimStep() {
      this.loaders.swim = true;

      const dates = this.fields.dates.sort();

      this.$store
        .dispatch("arnprior/podsStore/getSwimSlots", {
          appId: this.$route.params.id,
          fields: {
            check_in: dates[0],
            check_out: dates[1],
          },
        })
        .then((resp) => {
          this.swimSlots.anyAvailable = resp.swimSlotsAvailable;
          this.swimSlots.slots = resp.availableSwimSlots.filter((slot) => {
            return slot.time_slots.length > 0;
          });

          this.loaders.swim = false;
          this.stepPosition = 4;
        })
        .catch(() => (this.loaders.swim = false));
    },
    loadSummaryStep() {
      this.loaders.summary = true;

      const dates = this.fields.dates.sort();

      this.$store
        .dispatch("arnprior/podsStore/getSummary", {
          appId: this.$route.params.id,
          fields: {
            check_in: dates[0],
            check_out: dates[1],
            pods: this.fields.pods,
            extras: this.fields.extraQuantities,
            swim_slot: this.fields.swimSlot,
          },
        })
        .then((resp) => {
          this.fields.subTotal = resp.totals.subTotal;
          this.fields.vatTotal = resp.totals.vatTotal;
          this.fields.total = resp.totals.total;

          this.loaders.summary = false;
          this.stepPosition = 6;
        })
        .catch(() => (this.loaders.summary = false));
    },
    createBooking() {
      this.loaders.complete = true;

      const dates = this.fields.dates.sort();

      this.$store
        .dispatch("arnprior/podsStore/createBooking", {
          appId: this.$route.params.id,
          fields: {
            check_in: dates[0],
            check_out: dates[1],
            pods: this.fields.pods,
            extras: this.fields.extraQuantities,
            swim_slot: this.fields.swimSlot,
            customer_id: this.fields.customer_id,
            subTotal: this.fields.subTotal,
            vatTotal: this.fields.vatTotal,
            total: this.fields.total,
          },
        })
        .then((resp) => {
          this.$router.push({
            name: "module-arnprior-orders-individual",
            params: {
              orderId: resp.order_id,
            },
          });
        });
    },
    getExtraQuantityLabel(extraId) {
      const extra = this.extras.find((e) => {
        return e.id === parseInt(extraId);
      });

      return extra.name + " Quantity";
    },
  },
};
</script>
