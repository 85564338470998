<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Create a Booking</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="booking-form">
          <v-select
            label="Booking Type"
            v-model="fields.booking_type"
            :items="booking_types"
            item-text="title"
            item-value="value"
            outlined
            background-color="white"
          ></v-select>
          <glamping-pod v-if="fields.booking_type === 'glamping_pod'" />
          <pool v-else-if="fields.booking_type === 'pool'" />
          <pool-party v-else-if="fields.booking_type === 'pool_party'" />
          <party-pod v-else-if="fields.booking_type === 'party_pod'" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import GlampingPod from "./forms/GlampingPod";
import Pool from "./forms/Pool";
import PoolParty from "./forms/PoolParty";
import PartyPod from "./forms/PartyPod";

export default {
  components: {
    GlampingPod,
    Pool,
    PoolParty,
    PartyPod,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      stepPosition: 1,
      fields: {
        booking_type: null,
      },
      booking_types: [
        {
          title: "Glamping Pod",
          value: "glamping_pod",
        },
        {
          title: "Pool Booking",
          value: "pool",
        },
        {
          title: "Pool Party",
          value: "pool_party",
        },
        {
          title: "Party Pod",
          value: "party_pod",
        },
      ],
      errors: {},
    };
  },

  computed: {
    steps() {
      if (this.fields.booking_type === "glamping_pod") {
        return [
          {
            title: "Booking Type",
            position: 1,
          },
          {
            title: "Dates",
            position: 2,
          },
          {
            title: "Pods",
            position: 3,
          },
          {
            title: "Extras",
            position: 4,
          },
          {
            title: "Swimming",
            position: 5,
          },
          {
            title: "Customer Details",
            position: 6,
          },
        ];
      } else if (this.fields.booking_type === "pool") {
        return [
          {
            title: "Booking Type",
            position: 1,
          },
          {
            title: "Dates & Times",
            position: 2,
          },
          {
            title: "Customer Details",
            position: 3,
          },
        ];
      } else if (this.fields.booking_type === "pool_party") {
        return [
          {
            title: "Booking Type",
            position: 1,
          },
          {
            title: "Date",
            position: 2,
          },
          {
            title: "Booking Information",
            position: 3,
          },
          {
            title: "Customer Details",
            position: 4,
          },
        ];
      } else if (this.fields.booking_type === "party_pod") {
        return [
          {
            title: "Booking Type",
            position: 1,
          },
          {
            title: "Date",
            position: 2,
          },
          {
            title: "Booking Information",
            position: 3,
          },
          {
            title: "Customer Details",
            position: 4,
          },
        ];
      }

      return [
        {
          title: "Booking Type",
          position: 1,
        },
        {
          title: "Please choose a Booking Type...",
          position: 2,
        },
      ];
    },
  },

  methods: {
    openForm() {
      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.errors = {};
    },

    saveForm() {},
  },
};
</script>
